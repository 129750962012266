import React from 'react'

import TemplateController from '../category-page-template/template-controller'
import MarketView from './market-view'

const MarketController = ({ data, location }) => {
  const viewProps = TemplateController({
    data,
    location,
    slug: 'market-updates',
  })

  return <MarketView {...viewProps} />
}

export default MarketController
