import { graphql } from 'gatsby'
import React from 'react'

import MarketController from '../screens/market'

const Market = (props) => <MarketController {...props} />

export default Market

//market-updates temporarily hide WMPL translation since Lokalisation can't sync
export const pageQuery = graphql`
  query {
    articles: allWpMarketUpdate(sort: { fields: dateGmt, order: DESC }) {
      edges {
        node {
          id
          title
          slug
          nodeType
          content
          excerpt
          uri
          date(formatString: "MMM DD, YYYY")
          dateGmt
          categories {
            nodes {
              name
              slug
              description
              id
            }
          }
          acf: acfMarketUpdates {
            keywords
            readingDuration
            dateRange {
              dateFrom
              dateTo
            }
          }
          featuredImage {
            node {
              id
              altText
              sourceUrl
            }
          }
        }
      }
    }
  }
`
